<template>
    <section
    class="mx-5"
    >
        <v-row>
            <v-col
            cols="12"
            md="12"
            >
                <h2 class="headline">Usuarios</h2>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col
            cols="12"
            >
                <v-card>
                    <v-card-text>
                        <v-row>
                            <v-col
                            cols="12"
                            md="4"
                            >
                                <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Buscar usuario"
                                hide-details
                                single-line
                                >
                            </v-text-field>
                            </v-col>
                        </v-row>
                        <v-data-table
                        :headers="headers"
                        :items="users"
                        :search="search"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-menu
                                bottom
                                left
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                        @click="editUser(item)"
                                        v-can="'users-edit'"
                                        >
                                            <v-list-item-title>Editar</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item
                                        @click="showAlert(item)"
                                        v-can="'users-delete'"
                                        >
                                            <v-list-item-title>Eliminar</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-fab-transition>
            <v-btn
            color="pink"
            dark
            fixed
            bottom
            right
            fab
            @click="dialog = true"
            v-can="'users-create'"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                    icon
                    dark
                    @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Nuevo usuario</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                        dark
                        text
                        @click="submit"
                        >
                            Guardar
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col>
                                <v-text-field
                                label="Nombre"
                                v-model="$v.user.name.$model"
                                :error-messages="nameErrors"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                label="Correo electrónico"
                                v-model="$v.user.email.$model"
                                :error-messages="emailErrors"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field
                                label="Contraseña"
                                type="password"
                                v-model="$v.user.password.$model"
                                :error-messages="passwordErrors"
                                ></v-text-field>
                            </v-col>
                        </v-row>


                        <v-row class="mt-3">
                            <v-col
                            cols="12"
                            >
                                <v-card>
                                    <v-card-text>
                                        <v-row>
                                            <v-col
                                            cols="12"
                                            md="4"
                                            >
                                                <v-text-field
                                                v-model="roleSearch"
                                                append-icon="mdi-magnify"
                                                label="Search"
                                                hide-details
                                                single-line
                                                >
                                            </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-data-table
                                        v-model="user.roles"
                                        :headers="roleHeaders"
                                        :items="roles"
                                        :search="roleSearch"
                                        :footer-props="{'items-per-page-options':[-1]}"
                                        show-select
                                        >
                                        </v-data-table>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog> -->

        <Modal
        :dialog='dialog'
        :title='dialogTitle'
        @close='dialog = false'
        @execute='submit'
        >
            <template v-slot>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="Nombre"
                            v-model="$v.user.name.$model"
                            :error-messages="nameErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="Correo electrónico"
                            v-model="$v.user.email.$model"
                            :error-messages="emailErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                            label="Contraseña"
                            type="password"
                            v-model="$v.user.password.$model"
                            :error-messages="passwordErrors"
                            ></v-text-field>
                        </v-col>
                    </v-row>


                    <v-row class="mt-3">
                        <v-col
                        cols="12"
                        >
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col
                                        cols="12"
                                        md="4"
                                        >
                                            <v-text-field
                                            v-model="roleSearch"
                                            append-icon="mdi-magnify"
                                            label="Search"
                                            hide-details
                                            single-line
                                            >
                                        </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-data-table
                                    v-model="user.roles"
                                    :headers="roleHeaders"
                                    :items="roles"
                                    :search="roleSearch"
                                    :footer-props="{'items-per-page-options':[-1]}"
                                    show-select
                                    >
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Modal>

        <QuestionAlert
        :alert="onAlert"
        question="¿Esta seguro de eliminar el usuario?"
        @close="onAlert = false"
        @execute="deleteItem"
        />
    </section>
</template>

<script>
import {mapMutations} from "vuex"
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

import QuestionAlert from '@/components/QuestionAlert.vue'
import Modal from '@/components/Modal.vue'

export default {
    mixins: [validationMixin],
    data(){
       return {
           search: '',
           headers: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
               {
                   text: 'Correo electrónico',
                   value: 'email'
               },
               {
                   text: 'Acciones',
                   value: 'actions',
                   width: '5%',
                   sortable: false
               },
           ],
           users: [],
           user: {
               id: null,
               name: '',
               email: '',
               password: '',
               roles: []
           },
           dialog: false,
           roleSearch: '',
           roleHeaders: [
               {
                   text: 'Nombre',
                   value: 'name'
               },
           ],
           roles: [],
           itemToDelete: null,
           onAlert: false
       }
    },
   validations: {
       user: {
               name: {required},
               email: {required,email},
               password: {required}
        }
   },
   computed: {
       dialogTitle() {
           return (this.user.id != null) ? "Editar usuario" : "Nuevo usuario"
       },
      nameErrors () {
          const errors = []
          if (!this.$v.user.name.$dirty) return errors
          !this.$v.user.name.required && errors.push('Campo requerido')
          return errors
      },
      emailErrors () {
          const errors = []
          if (!this.$v.user.email.$dirty) return errors
          !this.$v.user.email.required && errors.push('Campo requerido')
          return errors
      },
      passwordErrors () {
          const errors = []
          if (!this.$v.user.password.$dirty) return errors
          !this.$v.user.password.required && this.user.id == null && errors.push('Campo requerido')
          return errors
      },
  },
   methods: {
        ...mapMutations(['setLayout','setNotify','setLoading']),
        get() {
            this.setLoading(true)

            this.axios.get('/users')
            .then( (response) => {
                this.setLoading(false)

                this.users = response.data.users
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        submit() {
            this.$v.$touch()

            if(!this.$v.user.name.$invalid
                && !this.$v.user.email.$invalid
            ){
                if(this.user.id == null){
                    if(!this.$v.user.password.$invalid){
                        this.save()
                    }
                }else{
                    this.update()
                }
            }
        },
        save() {
            this.setLoading(true)

            this.axios.post('/users',this.user)
            .then( (response) => {
                this.user.id = null
                this.user.name = ''
                this.user.email = ''
                this.setLoading(false)
                this.get()
                this.dialog = false

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        editUser(item){
            this.user.id = item.id
            this.user.name = item.name
            this.user.email = item.email
            this.user.roles = item.roles
            this.dialog = true
        },
        update(){
            this.setLoading(true)

            this.axios.put('/users/'+this.user.id,this.user)
            .then( (response) => {
                this.user.id = null
                this.user.name = ''
                this.user.email = ''
                this.setLoading(false)

                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})

                this.get()
                this.dialog = false
            })
            .catch( (error) => {
                this.dialog = false
                this.setLoading(false)
                console.log(error)
            })
        },
        deleteItem() {
            this.onAlert = false
            this.setLoading(true)

            this.axios.delete('/users/'+this.itemToDelete.id)
            .then( (response) => {
                this.itemToDelete = null
                this.setNotify({'show':true,
                                'color':'success',
                                'message':response.data.message,
                                'time':5000})
                this.get()
                this.search = ''
                this.setLoading(false)
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        getRoles() {
            this.setLoading(true)

            this.axios.get('/roles')
            .then( (response) => {
                this.setLoading(false)

                this.roles = response.data.roles
            })
            .catch( (error) => {
                this.setLoading(false)
                console.log(error)
            })
        },
        showAlert(item) {
            this.itemToDelete = item
            this.onAlert = true
        }
    },
    created(){
        this.setLayout('main-layout')
    },
    mounted(){
        this.get()
        this.getRoles()
    },
    watch: {
        "dialog": function () {
            if(!this.dialog){
                this.user.id = null
                this.user.name = ''
                this.user.email = ''
                this.user.password = ''
                this.user.roles = []
                this.$v.$reset()
            }

        },
    },
    components: {
        QuestionAlert,
        Modal
    }
}
</script>
